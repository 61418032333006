import { Component } from "react";
import { useMercuryContext } from "../../../user-context";
import { Helmet } from "react-helmet";
import { Error as ErrorWarning } from "../../../stories/Errors/Error";
import LoadingStar from "../../../ui/LoadingStar";
import DetailsPageHeader from "../../../components/projectDetailsPage/DetailsPageHeader";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
    Collapse,
    DatePicker,
    Descriptions,
    Form,
    Modal, Popover,
    Select,
    Space,
    Table,
    Tabs,
    Tooltip,
    Typography,
} from "antd";

import {
    BlockOutlined,
    BuildOutlined,
    CalendarOutlined,
    CheckOutlined,
    CloseOutlined,
    CompressOutlined,
    CopyOutlined,
    CreditCardOutlined,
    ExclamationCircleFilled,
    ExportOutlined,
    HourglassOutlined,
    InfoCircleOutlined,
    LinkOutlined,
    ReloadOutlined,
    ShareAltOutlined,
    UserSwitchOutlined,
    HistoryOutlined
} from "@ant-design/icons";

import DtpFilesSection from "../../../components/projectDetailsPage/DtpFilesSection";
import HelixIntegrationTab from "../../../components/projectDetailsPage/helixIntegration/HelixIntegrationTab";
import { ListExtByProject } from "../../../components/extensions/components/ListExtByProject/ListExtByProject";
import CaptionHubTab from "../../../components/projectDetailsPage/captionHub/CaptionHubTab";
import BackTopButton from "../../../ui/BackTopButton";
import Commenter from "../../../components/Comments/Commenter";
import OwnerAssign from "../../Owner.assign";
import { removeUnderscore, sorter } from "../../../utils/helpers";
import TimezoneConverter from "../../../timezone-converter";
import UserBadge from "../../../stories/UserIdentity/UserBadge";
import SharingPercentage from "../../../ui/SharingPercentage";
import NumericInput from "../../../ui/NumericInput";
import { Switch } from "../../../Components";
import { failedNotification, infoNotification, successfulNotification } from "../../../utils/notifications";
import ReferenceFiles from "../../../components/projectDetailsPage/generalInformation/ReferenceFiles";
import GoogleReferenceFiles from "../../../components/projectDetailsPage/generalInformation/GoogleReferenceFiles";
import { BASE_URL } from "../../../utils/variables";
import UpdateProjectStatus from "../../../containers/UpdateProjectStatus";
import UploadBtn from "../../../containers/UploadBtn";
import UploadBtnLegacy from "../../../containers/UploadBtnLegacy";
import LinkGSuiteFiles from "../../../containers/LinkGSuiteFiles";
import { RequestExtensions } from "../../../components/extensions/components/RequestExtension/RequestExtensions";
import UniversalButton from "../../../ui/UniversalButton";

import moment from "moment/moment";
import dayjs from 'dayjs';
import SessionStorage from "../../../Utilities/SessionStorage";
import { Link, useParams } from "react-router-dom";
import "./ProjectDetails.scss";
import {
    FiniteDropDownWithAsyncLoader,
    FiniteDropDownWithAsyncLoaderItem
} from "../../../stories/FiniteDropDown/FiniteDropDownWithAsyncLoader.abstract";
import { CaptionHubSendForm } from "../../../stories/CaptionHubSendForm/CaptionHubSendForm"
import StatusHistory from "./../../StatusHistory";

import type { TabsProps } from 'antd';
import GGLResyncButton from "../GGLResyncButton";
import SharingTab from "./SharingTab";
interface EditingGSuite {
    isEditing: boolean;
    editingRow: any;
}

interface ProjectDetailsState {
    loading: boolean;
    error?: Error;
    project?: any;
    disabled: boolean;
    qvProperties?: any;
    selectedDtpRows: any[];
    selectedDtpRowsKeys: any[];
    selectedPLPRows: any[];
    selectedPLPRowsKeys: any[];
    selectedExtData: any[];
    users: { email: string; name: string; _id: string }[];
    workflows: { title: string; description: string; _id: string }[];
    poTypes: { title: string; description: string; _id: string }[];
    productAreas: any[];
    productTypes: any[];
    plpSorting: {
        order: any;
        field: string;
    },
    updating: boolean;
    editingGSuite: EditingGSuite;
    notes: any[]
    costCodes: any[];
    costCodesLoading: boolean
}

const
    { Paragraph } = Typography,
    { Option } = Select,
    priorities = ["P0", "P1", "P2"];

class WorkflowDetails extends FiniteDropDownWithAsyncLoader {
    async fetch(signal: any): Promise<FiniteDropDownWithAsyncLoaderItem[]> {

        const { data } = await this.props.context.gpClient.get("/google-gp-v1/WorkflowType")

        return data.map((item: any) => {
            return {
                value: item._id,
                label: item.title
            }
        });
    }

}
class PODetails extends FiniteDropDownWithAsyncLoader {
    async fetch(signal: any): Promise<FiniteDropDownWithAsyncLoaderItem[]> {

        const { data } = await this.props.context.gpClient.get("/google-gp-v1/POType/paginate?page=1&limit=500&query[isActive][0]=true")

        return data[0]?.documents?.map((item: any) => {
            return {
                value: item._id,
                label: item.title
            }
        });
    }

}

class ProjectDetails extends Component<any, ProjectDetailsState> {

    private readonly id?: string;

    public state = {
        loading: false,
        error: undefined,
        project: undefined,
        disabled: false,
        qvProperties: undefined,
        selectedDtpRows: [],
        selectedDtpRowsKeys: [],
        selectedPLPRows: [],
        selectedPLPRowsKeys: [],
        selectedExtData: [],
        users: [],
        workflows: [],
        poTypes: [],
        productAreas: [],
        productTypes: [],
        plpSorting: {
            field: "targetLanguageCode",
            order: "ascend"
        },
        updating: false,
        editingGSuite: { isEditing: false, editingRow: "" },
        notes: [],
        costCodesLoading: true,
        costCodes: []
    }


    /*
        TODO:NOT REQUIRED POST MONGO
        Shim to match missing column data which will be provided by $lookup
     */

    private tempColumnData?: SessionStorage;


    set users(value: ProjectDetailsState["users"]) {
        this.setState({ users: value })
    }

    get users() {
        const colData = this.tempColumnData?.data || {};
        if (colData.users) {
            return colData.users
        }
        return this.state.users;
    }

    set workflows(value: ProjectDetailsState["workflows"]) {
        this.setState({ workflows: value })
    }

    get workflows() {
        const colData = this.tempColumnData?.data || {};
        if (colData.workflows) {
            return colData.workflows;
        }
        return this.state.workflows;
    }

    set poTypes(value: ProjectDetailsState["poTypes"]) {
        this.setState({ poTypes: value })
    }

    get poTypes() {
        const colData = this.tempColumnData?.data || {};
        if (colData.poTypes) {
            return colData.poTypes;
        }
        return this.state.poTypes;
    }

    set productAreas(value: ProjectDetailsState["productAreas"]) {
        this.setState({ productAreas: value })
    }

    get productAreas() {
        const colData = this.tempColumnData?.data || {};
        if (colData.productAreas) {
            return colData.productAreas;
        }
        return this.state.productAreas;
    }

    set productTypes(value: ProjectDetailsState["productTypes"]) {
        this.setState({ productTypes: value })
    }

    get productTypes() {
        const colData = this.tempColumnData?.data || {};
        if (colData.productTypes) {
            return colData.productTypes;
        }
        return this.state.productTypes;
    }

    // END TODO

    constructor(props: any) {
        super(props);
        if (props.projectId) {
            this.id = props.projectId as string;
        }
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<ProjectDetailsState>, snapshot?: any) {
        if (!prevState.project && this.state.project) {
            const
                project = this.state.project as any,
                socket = this.props.context.gpSocket.project;

            socket.emit("bind:ids", [project._id])
            socket.on(project._id, this.get);
        }
    }

    async componentDidMount() {

        if (!this.id) {
            this.setState({ error: new Error("No short id provided") })
        }

        this.props.context.client.get("/core-ccs-v1")
            .then( ( res:any ) => {
                const { data } = res;
                this.setState({
                    costCodes: data.map( ( item:any ) => {
                        return {
                            _id: item._id,
                            title: item.code,
                            value: item.code
                        }
                    }),
                    costCodesLoading: false
                })
            })

        this.tempColumnData = new SessionStorage("tempColumnData");
        this.fetch();
    }

    componentWillUnmount() {
        if (this.state.project) {
            const project = this.state.project as any;
            const socket = this.props.context.gpSocket.project;
            socket.emit("unbind:ids", [project._id])
            socket.off(project._id, this.get);
        }
    }

    async fetch() {
        this.setState({ loading: true })
        try {
            await this.get();
        } catch (e: any) {
            this.setState({ error: e, loading: false });
        }
    }

    get = async () => {
        const
            socket = this.props.context.gpSocket.project;

        socket.emit("getByShortId", this.id, ((project: any) => {
            this.setState({
                project: project,
                error: undefined,
                loading: false,
                qvProperties: project?.queryVariationProjectMetadata
            }, async () => {
                this.props.context.umsSocket.comment.emit( "getCommentsByRef", project._id, ( notes:any[] ) => {
                    this.setState( { notes } )
                })
            })
        }));

    }

    reload = async () => {
        // await this.fetch();
    }

    refreshPage = async () => {
        await this.fetch();
    }

    update = async (patch: any) => {
        if (!this.state.project) {
            return;
        }

        const {
            shortId
        } = this.state.project;

        if (this.props.id) {
            window.sessionStorage.clear();
        }
        this.setState({ updating: true })

        await this.props.context.gpClient.put(`/google-gp-v1/project/merge`, { shortIds: [shortId], ...patch });

        await this.get();

        this.setState({ updating: false });
        window.sessionStorage.clear()
    }

    topDetails() {

        const
            { qvProperties, project } = this.state,
            {
                product,
                shortId,
                componentType,
                sourceLanguageCode,
                // targetLanguageCode,
                customVendorProjectMetadata,
                turnaroundTimeUrgency,
                plps,
                queryVariationProjectMetadata,
                // dueDate,
                lastDueDate,
                creationTimestamp,
                lastImportedTimestamp,
                lastUpdatedTimestamp,
                lastSharedDate
            } = project as any,
            {
                projectStatus,
                STT,
                importedTimestamp,
                invoiceMonth,
                projectType,
                workflowType,
                poType,
                costCode
            } = customVendorProjectMetadata,
            { flags } = this.props.context,
            plpReviewDate = (plps as any[])?.reduce(((a, b) => a.reviewDateValue > b.reviewDateValue ? a : b), []);

        return (
            <Descriptions bordered={true} column={2} size={"small"}>
                <Descriptions.Item label="Product">{product}</Descriptions.Item>
                <Descriptions.Item label="Component Type">
                    {componentType}
                </Descriptions.Item>
                <Descriptions.Item label="Source Language">
                    {sourceLanguageCode}
                </Descriptions.Item>
                <Descriptions.Item label="Status">
                    {projectStatus
                        ? removeUnderscore(projectStatus)
                        : "Failed to generate Project Status"}
                </Descriptions.Item>
                <Descriptions.Item label="Urgency">
                    {turnaroundTimeUrgency}
                </Descriptions.Item>
                <Descriptions.Item label="Received Date">
                    <TimezoneConverter date={importedTimestamp} />
                </Descriptions.Item>
                <Descriptions.Item label="Latest Translation Due Date">
                    {lastDueDate ? (
                        <Tooltip
                            title="Displays the latest translation due date from all of the PLPs"
                            color={"var(--tertiary-color)"}
                            placement={"bottom"}
                        >
                            <TimezoneConverter date={lastDueDate} />
                            <InfoCircleOutlined
                                style={{ color: "var(--tertiary-color)", marginLeft: "3px" }}
                            />
                        </Tooltip>
                    ) : (
                        <Tooltip
                            title="No translation due date for the PLPs"
                            placement="left"
                            color="var(--red)"
                            overlayInnerStyle={{ width: "245px" }}
                        >
                            -
                        </Tooltip>
                    )}
                </Descriptions.Item>
                <Descriptions.Item
                    label="Project Type"
                    style={{ textTransform: "uppercase" }}
                >
                    {projectType}
                </Descriptions.Item>
                <Descriptions.Item
                    label="Latest Review Due Date"
                    style={{ textTransform: "uppercase" }}
                >
                    {plpReviewDate?.reviewDateValue ? (
                        <Tooltip
                            title="Displays the latest review due date from all of the PLPs"
                            color={"var(--tertiary-color)"}
                            placement={"bottom"}
                        >
                            <TimezoneConverter date={plpReviewDate?.reviewDateValue} />
                            <InfoCircleOutlined
                                style={{ color: "var(--tertiary-color)", marginLeft: "3px" }}
                            />
                        </Tooltip>
                    ) : (
                        <Tooltip
                            title="No review due date for the PLPs"
                            placement="left"
                            color="var(--red)"
                            overlayInnerStyle={{ width: "245px" }}
                        >
                            -
                        </Tooltip>
                    )}
                </Descriptions.Item>
                <Descriptions.Item
                    label="Last Shared Date"
                    style={{ textTransform: "uppercase" }}
                >
                    {lastSharedDate ? <TimezoneConverter date={lastSharedDate} /> : <i className={"muted"}>Not shared</i>}
                </Descriptions.Item>
                <Descriptions.Item label="STT" style={{ textTransform: "uppercase" }}>
                    {STT ? (
                        <Tooltip
                            title="Used STT when was sent to Helix"
                            placement="left"
                            color="var(--tertiary-color)"
                            overlayInnerStyle={{ width: "218px" }}
                        >
                            <CheckOutlined style={{ color: "var(--tertiary-color)" }} />
                        </Tooltip>
                    ) : (
                        <Tooltip
                            title="NOT used STT when was sent to Helix"
                            placement="left"
                            color="var(--red)"
                            overlayInnerStyle={{ width: "245px" }}
                        >
                            <CloseOutlined style={{ color: "var(--red)" }} />
                        </Tooltip>
                    )}
                </Descriptions.Item>
                {
                    (customVendorProjectMetadata as any).projectType === "QV" ? (
                        flags.queryVariations && qvProperties && <>
                            <Descriptions.Item label="Query Per Intent">
                                <NumericInput
                                    style={{
                                        width: 50,
                                    }}
                                    max={50}
                                    min={1}
                                    value={(qvProperties as any).queryPerIntent}
                                // onChange={(value: any) => {
                                //     this.setState({
                                //         qvProperties: {
                                //             ...qvProperties as any,
                                //             queryPerIntent: value
                                //         }
                                //     })
                                // }}
                                // onPressEnter={async (value: any) => {
                                //     let queryPerIntentInt = value === "" ? 5 : parseInt(value) < 5 ? 5 : parseInt(value);
                                //     // await submitQVData({queryPerIntent:queryPerIntentInt});
                                //     // setQVProperties({...qvProperties, queryPerIntent: queryPerIntentInt})
                                //     // handleRefresh();
                                // }}
                                />
                            </Descriptions.Item>

                            <Descriptions.Item label="Priority">
                                {/*TODO REF ORIGINIAL*/}
                                <Select disabled={false}
                                    // onChange={async (value) => {
                                    //     // setQVProperties({ ...qvProperties, priority: value })
                                    //     // setPriorityDisabled(true);
                                    //     // await submitQVData({ priority: value });
                                    //     // handleRefresh();
                                    // }}
                                    defaultValue={(qvProperties as any).priority ? (qvProperties as any).priority : queryVariationProjectMetadata?.priority}
                                    style={{
                                        width: 120,
                                    }}
                                >
                                    {priorities.map((priority) => {
                                        // @ts-ignore
                                        return <Option key={priority} value={priority}>{priority}</Option>
                                    })}

                                </Select>
                            </Descriptions.Item>
                            <Descriptions.Item label="Workflow">
                                <Paragraph editable={{
                                    // onChange: async (value) => {
                                    //     {/*TODO REF ORIGINIAL*/
                                    //     }
                                    //     // setQVProperties({ ...qvProperties, workflow: value })
                                    //     // await submitQVData({workflow:value});
                                    // }
                                }}>
                                    {(qvProperties as any).workflow}
                                </Paragraph>
                            </Descriptions.Item>
                            <Descriptions.Item label="Mercury Due Date"> <TimezoneConverter
                                date={queryVariationProjectMetadata?.calculatedDueDate} /> </Descriptions.Item>
                        </>
                    ) : (
                        <></>
                    )
                }
                {
                    <>
                        <Descriptions.Item label="Workflow type">
                            <WorkflowDetails
                                context={this.props.context}
                                id={"Workflow"}
                                name={"workflowType"}
                                value={workflowType}
                                placeholder={"No workflow assigned"}
                                style={{ width: "100%", maxWidth: "200px" }}
                                onChange={async (v: any) => {
                                    await this.update({
                                        customVendorProjectMetadata: {
                                            workflowType: v
                                        }
                                    })
                                }}
                            />
                        </Descriptions.Item>
                        <Descriptions.Item label="Cost Code">
                            <Select
                                showSearch
                                allowClear
                                placeholder="Select Cost Code"
                                disabled={this.state.costCodesLoading || this.state.updating || customVendorProjectMetadata.varStatus === "INVOICE"}
                                optionFilterProp="children"
                                value={costCode}
                                onChange={async (v: any) => {
                                    await this.update({
                                        customVendorProjectMetadata: {
                                            costCode: v
                                        }
                                    })
                                }}
                                filterOption={(input, option) =>
                                    // @ts-ignore
                                    option.children.toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {this.state.costCodes.map((so: any) => {
                                    return (
                                        <Option key={so?._id} value={so?.value}>
                                            {so.title}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Descriptions.Item>
                        <Descriptions.Item label="PO type">
                            <PODetails
                                context={this.props.context}
                                id={"poTypeSelect"}
                                name={"poType"}
                                value={poType}
                                placeholder={"No PO Type assigned"}
                                style={{ width: "100%", maxWidth: "200px" }}
                                onChange={async (v: any) => {
                                    await this.update({
                                        customVendorProjectMetadata: {
                                            poType: v
                                        }
                                    })
                                    await this.get();
                                }
                            }
                            />
                        </Descriptions.Item>
                        <Descriptions.Item label="Invoice month">
                            <DatePicker
                                picker="month"
                                format={"MM-YYYY"}
                                placeholder={"Select month"}
                                disabled={this.state.updating}
                                value={invoiceMonth ? dayjs(invoiceMonth) : undefined}
                                onChange={async (v) => {
                                    await this.update({
                                        customVendorProjectMetadata: {
                                            invoiceMonth: v
                                        }
                                    })
                                }}
                            />
                        </Descriptions.Item>
                        <Descriptions.Item label="Helix id">
                            {
                                (project as any)?.helixId
                                    ? <a href={`https://sdl.appiancloud.com/suite/sites/jobTracking`} target="_blank" rel="noopener noreferrer">
                                        <BlockOutlined className="project-link-icon" /> {(project as any).helixId}
                                    </a>
                                    : <i className={"muted"}>Not set</i>
                            }
                        </Descriptions.Item>
                        {/*//@ts-ignore*/}
                        <Descriptions.Item label="Expedited?" defaultValue={"0"}>
                            <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                checked={(project as any).customVendorProjectMetadata.expedited}
                                disabled={this.state.updating}
                                onChange={async (v) => {
                                    await this.update({
                                        customVendorProjectMetadata: {
                                            expedited: v
                                        }
                                    })
                                }}
                            />
                        </Descriptions.Item>
                        {/*//@ts-ignore*/}
                        <Descriptions.Item label="No extension?" defaultValue={"0"}>
                            <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                checked={(project as any).customVendorProjectMetadata.noExtension}
                                disabled={this.state.updating}
                                onChange={async (v) => {
                                    await this.update({
                                        customVendorProjectMetadata: {
                                            noExtension: v
                                        }
                                    })
                                }}
                            />
                        </Descriptions.Item>
                        {/*//@ts-ignore*/}
                        <Descriptions.Item label="Launched?" defaultValue={"0"}>
                            <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                checked={(project as any).customVendorProjectMetadata.launched}
                                disabled={this.state.updating}
                                onChange={async (v) => {
                                    await this.update({
                                        customVendorProjectMetadata: {
                                            launched: v
                                        }
                                    })
                                }}
                            />
                        </Descriptions.Item>
                        {/*//@ts-ignore*/}
                        <Descriptions.Item label="On hold?" defaultValue={"0"}>
                            <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                checked={(project as any).customVendorProjectMetadata.onHold}
                                disabled={this.state.updating}
                                onChange={async (v) => {
                                    await this.update({
                                        customVendorProjectMetadata: {
                                            onHold: v
                                        }
                                    })
                                }}
                            />
                        </Descriptions.Item>
                        {/*//@ts-ignore*/}
                        <Descriptions.Item label="PO Issued?" defaultValue={"0"}>
                            <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                checked={(project as any).customVendorProjectMetadata.POIssued}
                                disabled={this.state.updating}
                                onChange={async (v) => {
                                    await this.update({
                                        customVendorProjectMetadata: {
                                            POIssued: v
                                        }
                                    })
                                }}
                            />
                        </Descriptions.Item>
                        <Descriptions.Item label="Creation Timestamp">
                            <TimezoneConverter date={creationTimestamp} />
                        </Descriptions.Item>
                        <Descriptions.Item label="Last Imported Timestamp">
                            <TimezoneConverter date={lastImportedTimestamp} />
                        </Descriptions.Item>
                        {/*//@ts-ignore*/}
                        <Descriptions.Item label="Sales order" defaultValue={"0"} >
                            <Select
                                showSearch
                                allowClear
                                placeholder="Select SO"
                                disabled={this.state.updating}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    // @ts-ignore
                                    option.children.toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                                value={(project as any).customVendorProjectMetadata.salesOrder}
                                onChange={async (v) => {
                                    await this.update({
                                        customVendorProjectMetadata: {
                                            salesOrder: v
                                        }
                                    })
                                }
                                }
                            >
                                {[
                                    { _id: "1", title: "New", value: "New" },
                                    { _id: "2", title: "WIP", value: "WIP" },
                                    { _id: "3", title: "Invoiced", value: "Invoiced" },
                                    { _id: "4", title: "Canceled", value: "Cancelled" },
                                ].map((so: any) => {
                                    return (
                                        <Option key={so?._id} value={so?.value}>
                                            {so.title}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Descriptions.Item>
                        <Descriptions.Item label="Last Updated Timestamp">
                        <Tooltip
                            title="Last Updated Timestamp (From Google)"
                            color={"var(--tertiary-color)"}
                            placement={"bottom"}
                        >
                            <TimezoneConverter date={lastUpdatedTimestamp} />
                            <InfoCircleOutlined
                                style={{ color: "var(--tertiary-color)", marginLeft: "3px" }}
                            />
                        </Tooltip>
                        </Descriptions.Item>
                        {/*//@ts-ignore*/}
                        <Descriptions.Item
                            label={<Link to={`/invoicing-project/${shortId}`}>
                                <LinkOutlined /> View Project Invoicing
                            </Link>
                            }
                        ></Descriptions.Item>
                        {
                            flags.gglResyncProjectDetails && <Descriptions.Item label={"Download"}>
                              <GGLResyncButton projects={project} />
                            </Descriptions.Item>
                        }
                    </>
                }
            </Descriptions>
        )
    }

    projectInstructions() {

        const
            { project } = this.state,
            {
                description
            } = project!;

        return (
            <Descriptions
                bordered={true}
                size={"small"}
                column={3}
                title="Project Instructions:"
                style={{ marginTop: "5px", border: "1px solid #e1e7eb" }}
                extra={
                    <Tooltip
                        title="Copy to Clipboard"
                        color={"var(--tertiary-color)"}
                        placement={"left"}
                    >
                        <CopyToClipboard text={description ? description : ""}>
                            <CopyOutlined
                                style={{ color: "var(--tertiary-color)", padding: "5px 5px 0" }}
                                onClick={description ? () =>
                                    successfulNotification(
                                        "Copied successfully",
                                        "Project instructions copied to clipboard."
                                    )
                                    : () => { }}
                            />
                        </CopyToClipboard>
                    </Tooltip>
                }
            >
                <Descriptions.Item>
                    {description === null || description === undefined ? "" : description}
                </Descriptions.Item>
            </Descriptions>
        )
    }

    QAInstructions() {

        const {
            project
        } = this.state,
            { questionAnswerInstructions: QA } = project!,
            questionAnswerInstructions = QA as any[];

        // Displays all Q + A
        const questionsAnswers =
            questionAnswerInstructions &&
            questionAnswerInstructions.map((item: any, index: number) => (
                <Descriptions.Item label={item.question} key={index}>
                    {item.answer}
                </Descriptions.Item>
            ));

        // Creates the text that will be copied to clipboard from QA
        let first = true;
        const textToCopy = questionAnswerInstructions && questionAnswerInstructions.map((item) => {
            let format = ""
            if (first) {
                first = false;
                format = `${item.question}: ${item.answer}`;
            } else {
                format = `\n${item.question}: ${item.answer}`;
            }
            return format;
        });

        return (
            questionAnswerInstructions && (
                <Descriptions
                    bordered={true}
                    size={"small"}
                    column={3}
                    title="Questions & Answers Instructions:"
                    style={{ marginTop: "5px", border: "1px solid #e1e7eb" }}
                    extra={
                        <Tooltip
                            title="Copy to Clipboard"
                            color={"var(--tertiary-color)"}
                            placement={"left"}
                        >
                            <CopyToClipboard text={textToCopy.join("")}>
                                <CopyOutlined
                                    style={{ color: "var(--tertiary-color)", padding: "5px 5px 0" }}
                                    onClick={() =>
                                        successfulNotification(
                                            "Copied successfully",
                                            "All Questions & Answers Instructions were copied to your clipboard."
                                        )
                                    }
                                />
                            </CopyToClipboard>
                        </Tooltip>
                    }
                >
                    {questionsAnswers}
                </Descriptions>
            )
        );
    }

    referenceFiles() {

        const
            {
                project
            } = this.state,
            {
                shortId,
                customVendorProjectMetadata,
                referenceDataGoogle,
                referenceFiles,
            } = project!,
            {
                projectStatus
            } = customVendorProjectMetadata;


        return (
            <>
                <ReferenceFiles
                    data={referenceFiles || []}
                    limit={6}
                    accessToken={this.props.context.accessToken}
                    shortId={shortId}
                    projectStatus={projectStatus} />
                <GoogleReferenceFiles
                    data={referenceDataGoogle || []}
                    limit={6}
                    accessToken={this.props.context.accessToken}
                    shortId={shortId}
                    projectStatus={projectStatus}
                />
            </>
        )
    }

    clearLPRows = () => {
        this.setState({ selectedPLPRows: [], selectedPLPRowsKeys: [], selectedExtData: [] })
    }

    updateInternalDueDate = () => {
        const
            [form] = Form.useForm(),
            { project } = this.state,
            {
                _id,
                queryVariationProjectMetadata
            } = project!;


        const submitQVData = async (data: any) => {
            try {
                const result = this.props.context.legacyClient.patch(`/project/${_id}/qv`)
                successfulNotification("Success", "QV data Updated")
                return result;
            }
            catch (e: any) {
                failedNotification("Error", `Failed to update QV Data - ${e.toString()}`)
            }
        }

        return (
            Modal.confirm({
                title: "Confirmation needed",
                onOk: async () => {
                    try {
                        let values = await form.validateFields();
                        let internalDueDateMoment = values.internalDueDate
                            ? values.internalDueDate
                            : null;

                        if (!internalDueDateMoment) {
                            return;
                        }

                        let hours =
                            parseInt(internalDueDateMoment._d.getUTCHours()) < 10
                                ? `0${parseInt(internalDueDateMoment._d.getUTCHours())}`
                                : parseInt(internalDueDateMoment._d.getUTCHours());
                        let minutes =
                            parseInt(internalDueDateMoment._d.getUTCMinutes()) < 10
                                ? `0${parseInt(internalDueDateMoment._d.getUTCMinutes())}`
                                : parseInt(internalDueDateMoment._d.getUTCMinutes());
                        let seconds =
                            parseInt(internalDueDateMoment._d.getUTCSeconds()) < 10
                                ? `0${parseInt(internalDueDateMoment._d.getUTCSeconds())}`
                                : parseInt(internalDueDateMoment._d.getUTCSeconds());
                        let year = internalDueDateMoment._d.getUTCFullYear();
                        let month =
                            parseInt(internalDueDateMoment._d.getUTCMonth() + 1) < 10
                                ? `0${parseInt(internalDueDateMoment._d.getUTCMonth() + 1)}`
                                : parseInt(internalDueDateMoment._d.getUTCMonth() + 1);
                        let date =
                            parseInt(internalDueDateMoment._d.getUTCDate()) < 10
                                ? `0${internalDueDateMoment._d.getUTCDate()}`
                                : internalDueDateMoment._d.getUTCDate();

                        let dateTime = `${year}-${month}-${date}T${hours}:${minutes}:${seconds}.000Z`;

                        // notify user if Internal Due Date is later than Calculated Due Date
                        let internalDueDateCheck = new Date(dateTime);
                        let calculatedDueDateCheck = new Date(
                            (queryVariationProjectMetadata as any).calculatedDueDate
                        );

                        if (internalDueDateCheck > calculatedDueDateCheck) {
                            infoNotification("Date", "Due Date is later than Mercury Due Date");
                        }

                        //await new Promise(r => setTimeout(r, 2000));
                        await submitQVData({ internalDueDate: dateTime });
                        await this.reload();
                    } catch (error) {
                        failedNotification("Error", "Failed to update QV Data");
                    }
                },
                content: (
                    <Form form={form} layout="vertical" name="InternalDueDateForm">
                        <Form.Item
                            name="internalDueDate"
                            initialValue={
                                (queryVariationProjectMetadata as any).internalDueDate
                                    ? moment(
                                        (queryVariationProjectMetadata as any).internalDueDate.replace(
                                            ".000Z",
                                            ""
                                        )
                                    )
                                    : null
                            }
                        >
                            <DatePicker
                                onOk={(internalDueDateMoment) => {
                                    let hours =
                                        //@ts-ignore
                                        parseInt(internalDueDateMoment._d.getUTCHours()) < 10 ? `0${parseInt(internalDueDateMoment._d.getUTCHours())}` : parseInt(internalDueDateMoment._d.getUTCHours());
                                    let minutes =
                                        //@ts-ignore
                                        parseInt(internalDueDateMoment._d.getUTCMinutes()) < 10 ? `0${parseInt(internalDueDateMoment._d.getUTCMinutes())}` : parseInt(internalDueDateMoment._d.getUTCMinutes());
                                    let seconds =
                                        //@ts-ignore
                                        parseInt(internalDueDateMoment._d.getUTCSeconds()) < 10 ? `0${parseInt(internalDueDateMoment._d.getUTCSeconds())}` : parseInt(internalDueDateMoment._d.getUTCSeconds());
                                    //@ts-ignore
                                    let year = internalDueDateMoment._d.getUTCFullYear();
                                    let month =
                                        //@ts-ignore
                                        parseInt(internalDueDateMoment._d.getUTCMonth() + 1) < 10 ? `0${parseInt(internalDueDateMoment._d.getUTCMonth() + 1)}` : parseInt(internalDueDateMoment._d.getUTCMonth() + 1);
                                    let date =
                                        //@ts-ignore
                                        parseInt(internalDueDateMoment._d.getUTCDate()) < 10 ? `0${internalDueDateMoment._d.getUTCDate()}` : internalDueDateMoment._d.getUTCDate();

                                    let dateTime = `${year}-${month}-${date}T${hours}:${minutes}:${seconds}.000Z`;

                                    // notify user if Internal Due Date is later than Calculated Due Date
                                    let internalDueDateCheck = new Date(dateTime);
                                    let calculatedDueDateCheck = new Date(
                                        (queryVariationProjectMetadata as any).calculatedDueDate
                                    );

                                    if (internalDueDateCheck > calculatedDueDateCheck) {
                                        infoNotification(
                                            "Date Check",
                                            "Due Date is later than Mercury Due Date"
                                        );
                                    }
                                }}
                                showTime={true}
                            />
                        </Form.Item>
                    </Form>
                ),
                mask: true,
                okText: "Confirm",
                cancelText: "Cancel",
                //onOk: handleDeleteProject,
                okType: "danger",
                className: "action-btn delete-btn",
            })
        )
    }

    // select row from dtp table
    selectDtpRow = (rowKeys: any[], rows: any[]) => {
        // console.log('selecting dtp row: ', 'keys', rowKeys, 'rows', rows)
        this.setState({ selectedDtpRowsKeys: rowKeys, selectedDtpRows: rows })
    }

    // select row from plps table
    selectRow = (rows: any[], keys: any[]) => {
        // console.log('selecting plp row: ', 'rows', rows, 'keys', keys)
        const
            { project } = this.state,
            {
                product,
                title,
                shortId,
                sourceLanguageCode,
                plps,
                turnaroundTimeUrgency,
                customVendorProjectMetadata,
                // productType,
                productArea,
                isNonExtendable
            } = project!,
            { projectType } = customVendorProjectMetadata,
            { userDetails } = this.props.context,
            selectedExtData: any[] = [],
            [matchPLP] = keys.map((item: any) =>
                (plps as any).find(
                    (plp: any) => plp?.targetLanguageCode === item?.targetLanguageCode
                )
            ),
            matchProductArea = productArea,
            earliestReceivedDate = matchPLP?.documents
                ?.map((item: any) => item.translationVendorReceivedTimestamp)
                .reduce((a: any, b: any) => (Date.parse(a) > Date.parse(b) ? b : a)),
            legallySensitive = matchPLP?.documents?.filter(
                (doc: any) => doc?.legallySensitive?.isSet
            ).length,
            statusHistory = matchPLP?.customVendorPLPMetadata?.statusHistory && matchPLP?.customVendorPLPMetadata?.statusHistory.find((item: any) => item.status === 'IN_TRANSLATION');

        keys.map((item: any, index: number) => {
            console.log('earliestReceivedDate: ', JSON.stringify(earliestReceivedDate))
            console.log('statusHistoryTimeStamp: ', JSON.stringify(statusHistory?.timestamp))
            return selectedExtData.push({
                key: index,
                title: (title as string).toLowerCase().includes("covid"),
                projectId: shortId,
                shortId,
                sourceLanguageCode: sourceLanguageCode,
                targetLanguageCode: item.targetLanguageCode,
                originalDueDate: matchPLP?.latestOriginalTranslationDueTimestamp,
                urgency: turnaroundTimeUrgency,
                plpStatus: matchPLP?.plpStatus,
                docStatus: item?.status,
                requestedBy: userDetails?.email,
                product: product,
                productArea: matchProductArea,
                dueDateTier: matchPLP?.highestDueDateTier,
                componentType: matchPLP?.componentType,
                nonExtendable: isNonExtendable,
                receivedDate: statusHistory?.timestamp ? statusHistory?.timestamp : earliestReceivedDate,
                legallySensitive: legallySensitive !== 0 ? true : false,
                projectType,
            })
        });

        this.setState({ selectedPLPRows: rows, selectedPLPRowsKeys: keys, selectedExtData })

    }

    // delete dtp file
    handleDeleteUploadededDocument = async (
        dtpFileId: string,
        uploadedDocumentId: string
    ) => {

        try {

            await this.props.context.legacyClient.delete(`/projects/dtpfiles/${dtpFileId}/uploaded/delete/${uploadedDocumentId}`)
            successfulNotification(`Removed the uploaded document`, `If file is still visible, please refresh`);

        } catch (error) {

            console.error('Failed deleting uploaded file: ', error)
            failedNotification('Failed to delete uploaded file', 'Please try again.');

        }

        this.refreshPage();
    };

    handleClickEditGSuite = (record: any) => {
        this.setState({ editingGSuite: { isEditing: true, editingRow: record } })
    };

    handleSubmitEditGSuite = async (submitted: boolean, values: any) => {

        if (!submitted) {
            this.setState({ editingGSuite: { isEditing: false, editingRow: "" } })
            return;
        }

        try {

            const { project } = this.state,
                { shortId } = project!;

            await this.props.context.legacyClient.post(`/project/${shortId}/uploadedFileId`, values)
                .then((result: any) => {
                    let updatedFileId = result.data.customVendorFileMetadata.uploadedFileId;
                    let displayName = result.data.displayName;

                    successfulNotification(
                        `File id changed to ${updatedFileId} on ${displayName}`,
                        "Please refresh the page if the new file id is not visible."
                    );
                });

            this.setState({ editingGSuite: { isEditing: false, editingRow: "" } });

        } catch (error) {
            console.error('Error editing gSuite file id:', error);

            // failedNotification(error.response.data.error, error.response.data.detail);
            failedNotification('Failed to update file ID', 'Please try again');
        }

        this.reload();
    };

    actions = () => {

        const
            { flags } = this.props.context,
            {
                selectedDtpRows,
                project
            } = this.state,
            {
                shortId,
                customVendorProjectMetadata,
                files
            } = project!,
            {
                projectStatus,
                projectType
            } = customVendorProjectMetadata,
            linkGSuiteProps = {
                isGSuiteProject: projectType === "DTP_V2" &&
                    (files as [] || []).some((item: any) => item.fileUri && item.fileUri !== null),
                shortId: shortId,
                baseURI: BASE_URL,
                accessToken: this.props.context.accessToken,
                gSuiteFiles: projectType === "DTP_V2" &&
                    (files as [] || []).filter(
                        (file: any) =>
                            file.customVendorFileMetadata.status === "AWAITING_POST_PROCESSING" ||
                            file.customVendorFileMetadata.status === "POST_PROCESSED_UPLOADED" ||
                            file.customVendorFileMetadata.status === "FAILED_POST_PROCESSING"
                    ),
                projectStatus,
            }

        const uploadBtnItems = {
            title: "Upload Files", // The title for the upload pop up
            baseURI: BASE_URL, // The base URI for the API
            accessToken: this.props.context.accessToken, // The JWT access token from Auth0
            shortId, // The short ID of the project to upload for
            activeProjectLink: false,
            dtpFiles: files || [],
            projectStatus: projectStatus
        }

        const items = [
            {
                key: "1",
                label: "Actions",
                className: "actions",
                children: <Space
                    size="large"
                    style={{
                        width: "100%",
                        alignItems: "flex-start",
                        margin: "15px 16px",
                    }}
                >
                    {projectType === "DTP_V2" &&
                        (selectedDtpRows.length < 1 ? (
                            <Tooltip
                                title="Select at least 1 document"
                                placement="bottom"
                                color={"var(--tertiary-color)"}
                            >
                                <Select
                                    style={{ width: 135 }}
                                    placeholder={"Change Status"}
                                    popupMatchSelectWidth={false}
                                    disabled
                                ></Select>
                            </Tooltip>
                        ) : (
                            <UpdateProjectStatus {...{
                                title: "Updating Status",
                                baseURI: BASE_URL, // The base URI for the API
                                accessToken: this.props.context.accessToken, // The JWT access token from Auth0
                                shortId, // The short ID of the project to download a file from
                                //passed the entire selected row details to be able to display the document name
                                details: selectedDtpRows, // The resource name of the file to download found in the fileDataRef object of the file
                                // status: '', // The status to update the files to, will be taken from dropdown value in UpdateStatus.js
                                handleRefresh: this.reload,
                                projectStatus: projectStatus,
                            }} />
                        ))}

                    {/* Use old uploader if flag is on */}
                    {flags.autoUploadXliff ? (
                        <UploadBtnLegacy {...uploadBtnItems} />
                    ) : (
                        <UploadBtn {...uploadBtnItems} />
                    )}
                    {projectType === "DTP_V2" &&
                        linkGSuiteProps.isGSuiteProject &&
                        linkGSuiteProps.projectStatus !== "IN_PREPROCESSING" &&
                        linkGSuiteProps.projectStatus !== "PREPROCESSING_COMPLETE" && (
                            <LinkGSuiteFiles {...linkGSuiteProps} />
                        )}
                    <RequestExtensions
                        extensions={this.state.selectedExtData}
                        matchPLPLanguageCodes={(selectedPLPRowsKeys: any[]) => {
                            this.setState({ selectedPLPRowsKeys })
                        }}
                        clearLPRows={this.clearLPRows}
                    />
                    <UniversalButton
                        clickMethod={this.refreshPage}
                        text="Refresh"
                        icon={<ReloadOutlined />}
                        type={undefined} />
                    {
                        flags.queryVariations &&
                            projectType === "QV" ? (
                            <UniversalButton
                                clickMethod={this.updateInternalDueDate}
                                text="Edit Internal Due Date"
                                type={undefined}
                                icon={undefined} />
                        ) : (
                            <></>
                        )}
                </Space>,
            },
        ];

        return (
            <Collapse
                style={{ margin: "0 15px 20px 15px" }}
                defaultActiveKey={["1"]}
                items={items}
            />
        )
    }

    projectDetails() {
        const items = [
            {
                key: "1",
                label: "General Information",
                children: <>
                    {this.topDetails()}
                    {this.projectInstructions()}
                    {this.QAInstructions()}
                    {this.referenceFiles()}
                </>,
                className: "general-information"
            },
        ];
        return (
            <Collapse
                defaultActiveKey={["1"]}
                items={items}
                style={{ marginBottom: "10px" }}
            />
        )
    }


    tabs() {

        const { flags } = this.props.context;

        const {
            project,
            plpSorting,
            selectedPLPRows,
            selectedDtpRowsKeys,
            selectedDtpRows
        } = this.state,
            {
                _id,
                shortId,
                owners,
                plps,
                documents,
                customVendorProjectMetadata,
            } = project as any,
            {
                projectType
            } = customVendorProjectMetadata;

        const tabItems: TabsProps['items'] = [
            {
                key: '1',
                label: <span><CreditCardOutlined /> Project Details</span>,
                children: <>{this.actions()}
                    <DtpFilesSection
                        {...{
                            details: this.state.project,
                            rowSelectionDtpFiles: {
                                selectedRowKeys: selectedDtpRowsKeys,
                                onChange: this.selectDtpRow
                            },
                            selectedDtpRows: selectedDtpRows,
                            downloadProps: {
                                baseURI: BASE_URL, // The base URI for the API
                                accessToken: this.props.context.accessToken, // The JWT access token from Auth0
                                shortId, // The short ID of the project to download a file from
                                resourceName: selectedDtpRows.map( ( row:any ) => row.resourceName ), // The resource name of the file to download found in the fileDataRef object of the file
                            },
                            readyProjectType: projectType,
                        }}
                        values={{ editing: this.state.editingGSuite }}
                        handlers={{
                            handleClickFinishEditing: this.handleSubmitEditGSuite,
                            handleClickEdit: this.handleClickEditGSuite,
                            handleDeleteUploadededDocument: this.handleDeleteUploadededDocument,
                            resetDtpTableSelection: console.log,
                        }}
                    />

                    <Collapse
                        defaultActiveKey={["1"]}
                        style={{ margin: "0 15px 20px 15px" }}
                        items={[
                            {
                                key: "1",
                                label: "Language Pairs",
                                className: "language-pairs",
                                extra: `Total: ${plps.length}`,
                                children: <Table
                                    columns={[
                                        {
                                            title: "Target Language",
                                            dataIndex: "targetLanguageCode",
                                            align: "center",
                                            sorter: {
                                                compare: (a, b) => a.targetLanguageCode.localeCompare(b.targetLanguageCode),
                                            },
                                            sortOrder: (plpSorting.field === "targetLanguageCode" && plpSorting.order) as any,
                                            render: (text, record) => {
                                                const plpId = record?.plpId;
                                                return (
                                                    <>
                                                        <a
                                                            href={`/plps/${plpId}`}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <BlockOutlined style={{ marginRight:'3px' }}/>
                                                        </a>
                                                        <Link
                                                            to={`/plps/${plpId}`}
                                                            id={plpId}
                                                        >
                                                            {text}
                                                        </Link>
                                                    </>                
                                                )
                                            },
                                        },
                                        {
                                            title: "Document",
                                            dataIndex: "title",
                                            align: "left",
                                            render: (text, record) => {
                                                return <a rel="noopener noreferrer" target={"_blank"} href={`https://localization.google.com/polyglot/${record.documentId}`}><ExportOutlined /> {text}</a>
                                            }
                                        },
                                        {
                                            title: "Auto Completed",
                                            dataIndex: "autoCompleted",
                                            render: (text, record) => {
                                                switch (record.autocompleted) {
                                                    case "PENDING":
                                                        return <Popover content={"The document has been sent for autocompletion, but has not yet been resolved"} >
                                                            <HourglassOutlined style={{ color: "var(--tertiary-color)" }} />
                                                        </Popover>
                                                    case true:
                                                        return <CheckOutlined style={{ color: "var(--tertiary-color)" }} />
                                                    case false:
                                                        return <Popover content={"The document is waiting to be sent for autocompletion."} >
                                                            <CloseOutlined />
                                                        </Popover>

                                                    case "FAILED":
                                                        return <Popover content={"Autocompletion of this document has failed. IT will automatically be retried."} >
                                                            <ExclamationCircleFilled style={{ color: "var(--red)" }} />
                                                        </Popover>

                                                    case "N/A":
                                                    default:
                                                        return <span>-</span>

                                                }
                                            }
                                        },
                                        {
                                            title: "Status",
                                            dataIndex: "status",
                                            align: "left",
                                            sorter: {
                                                compare: (a, b) => a.status.localeCompare(b.status),
                                            },
                                            sortOrder: (plpSorting.field === "status" && plpSorting.order) as any,
                                            render: (text) => removeUnderscore(text),
                                        },
                                        {
                                            title: "Urgency",
                                            dataIndex: "turnaroundTimeUrgency",
                                            align: "left",
                                            render: (text) => text,
                                        },
                                        {
                                            title: "Due Date",
                                            dataIndex: "dueDate",
                                            sortDirections: ["descend", "ascend"],
                                            // Converts to unix form to be able to sort
                                            sorter: {
                                                compare: (a, b) =>
                                                    new Date(a.dueDate).getTime() / 1000 -
                                                    new Date(b.dueDate).getTime() / 1000,
                                            },
                                            sortOrder: (plpSorting.field === "dueDate" && plpSorting.order) as any,
                                            align: "left",
                                            render: (text, record) => {
                                                // console.log( "DD", text, record )
                                                return text ? <TimezoneConverter date={text} /> : "Not set"
                                            }
                                        },
                                        {
                                            title: "Sharing",
                                            dataIndex: "sharingStatus",
                                            sorter: {
                                                compare: (a, b) => a.sharingStatus - b.sharingStatus,
                                            },
                                            sortOrder: plpSorting.field === "sharingStatus" ? plpSorting.order : undefined,
                                            align: "center",
                                            render: (text, record) => (
                                                <SharingPercentage percentage={text} date={record.lastSharedDate} />
                                            ),
                                        },
                                        {
                                            title: "Weighted Word Count",
                                            dataIndex: "weightedWordCount",
                                            sorter: {
                                                compare: (a, b) => a.weightedWordCount - b.weightedWordCount,
                                            },
                                            align: "left",
                                            sortOrder: plpSorting.field === "weightedWordCount" && plpSorting.order,
                                            render: (text) => text,
                                        }
                                    ]}
                                    dataSource={documents.map((d: any, i: number) => {
                                        const plpLookup = (plps).reduce((acc: any, plp: any) => {
                                            acc[plp.targetLanguageCode] = plp._id;
                                            return acc;
                                          }, {});
                                        return { ...d, key: d._id, plpId: plpLookup[d?.targetLanguageCode] || null }
                                    })}
                                    className={"language-pairs-table"}
                                    pagination={false}
                                    rowSelection={{
                                        selectedRowKeys: selectedPLPRows,
                                        onChange: this.selectRow,
                                    }}
                                    onChange={(pagination, filters, sort: any) => {
                                        this.setState({ plpSorting: sort })
                                    }}
                                />,
                            },
                        ]}
                    />
                    {/* <LanguagePairsSection
                {...{ details:this.state.project!}}
                handlers={{
                    handleTableRowSelection:{
                        onChange: (selectedRowKeys:any[], selectedRows:any[]) => {
                                this.setState({
                                selectedPLPRows: selectedRows,
                                    selectedPLPRowsKeys: selectedRowKeys
                                })
                                this.selectRow( selectedRows, selectedRowKeys )
                            }
                        }
                    }}
                values={{
                    selectedRowsID:this.state.selectedPLPRowsKeys
                }}
                /> */}
                </>,
            },
            {
                key: '2',
                label: <span><UserSwitchOutlined /> Project Ownership</span>,
                children: <>
                    <div className="ownership-actions" style={{ padding: "0 15px" }}>
                        <OwnerAssign
                            bust={() => window.sessionStorage.clear()}
                            block={false} text={"Ownership"} selectedRows={[{ key: _id, shortId: shortId }]}
                            reload={this.get} users={this.users as any} />
                    </div>

                    <Table
                        columns={[
                            {
                                title: "User",
                                dataIndex: "user",
                                key: "user",
                                width: 350,
                                sorter: (a, b) => sorter(a.user.firstName, b.user.firstName),
                                render: (text, record) => {
                                    return <UserBadge
                                        name={`${record.user.firstName} ${record.user.lastName}`}
                                        email={record.user.email}
                                    />
                                }

                            },
                            {
                                title: "Started",
                                dataIndex: "startDate",
                                key: "startDate",
                                sorter: (a, b) => sorter(new Date(a.startDate), new Date(b.startDate)),
                                render: (text, record) => <TimezoneConverter date={text} />,
                                defaultSortOrder: "descend",
                            },
                            {
                                title: "Assigned by",
                                dataIndex: "assigner",
                                key: "assign",
                                sorter: (a, b) => sorter(a.assignedBy.firstName, b.assignedBy.firstName),
                                render: (text, record) => {
                                    if (!record.assignedBy) return null;
                                    return <UserBadge
                                        name={`${record.assignedBy.firstName} ${record.assignedBy.lastName}`}
                                        email={record.assignedBy.email}
                                        hideAvatar={true}
                                    />
                                }
                            },
                            {
                                title: "Ended",
                                dataIndex: "endDate",
                                key: "ended",
                                sorter: (a, b) => sorter(new Date(a.endDate), new Date(b.endDate)),
                                render: (text) => (!text ? "" : <TimezoneConverter date={text} />),
                            },
                            {
                                title: "Released by",
                                dataIndex: "releaser",
                                key: "releaser",
                                sorter: (a, b) => sorter(a.releasedBy.firstName, b.releasedBy.firstName),
                                render: (text, record) => {
                                    if (!record.releasedBy) return null;
                                    return <UserBadge
                                        name={`${record.releasedBy.firstName} ${record.releasedBy.lastName}`}
                                        email={record.releasedBy.email}
                                        hideAvatar={true}
                                    />
                                }
                            },
                            {
                                title: `Total: ${owners?.length}`,
                                width: 70,
                            },
                        ]}
                        key='owners-table'
                        dataSource={owners.map((o: any) => { return { ...o, key: o._id } })}
                        className={"ownership-table"}
                        size="small"
                        style={{ margin: "10px 15px 20px 15px" }}
                        pagination={{
                            position: ["bottomCenter"],
                            pageSize: 20,
                        }}
                    />
                </>,
            },
            {
                key: '3',
                label: <span><ShareAltOutlined /> Project Sharing</span>,
                children: <SharingTab
                    // projects={project}
                    shortId={shortId}
                />,
            },
            {
                key: '4',
                label: <span><CompressOutlined /> Helix Integration</span>,
                children: <HelixIntegrationTab projectId={this.id} />,
            },
            {
                key: '5',
                label: <span><CalendarOutlined /> Extensions</span>,
                children: <Collapse
                    defaultActiveKey={["1"]}
                    style={{ margin: "0 15px 20px 15px" }}
                    items={[{
                        key: "1",
                        label: "Extensions",
                        children: <ListExtByProject projectId={this.id} />,
                        className: "extensions"
                    }]}
                />,
            },
            {
                ...flags.captionHubIntegration && projectType === "LQE_VIDEO" && {
                    key: '6',
                    label: <span><BuildOutlined /> CaptionHub</span>,
                    children: <>
                        <CaptionHubSendForm
                            projectDoc={this.state.project}
                            baseURL={process.env.REACT_APP_SERVICES_API_URL}
                            context={this.props.context}
                            onWizardClose={(dataChanged: any) => {
                                if (dataChanged) {
                                    this.reload();
                                }

                            }}
                        />
                        <CaptionHubTab projectDoc={this.state.project} />
                    </>,
                }
            },
            {
                key: "7",
                label: (
                  <span>
                    <HistoryOutlined /> Status History
                  </span>
                ),
                children: (
                  <StatusHistory
                    statusHistory={(project as any).customVendorProjectMetadata?.statusHistory}
                  />
                ),
              },
        ]

        return (
            <Tabs defaultActiveKey="1" size="small" items={tabItems} />
        )
    }

    render() {

        const
            { project, notes } = this.state;

        if (this.state.error) {
            // @ts-ignore
            return <ErrorWarning reload={this.reload} loading={this.state.loading} error={this.state.error.toString()} />
        }

        if (this.state.loading || !project) {
            return (
                <LoadingStar
                    logo={true}
                    title={
                        <div>
                            Retrieving details for project: <br /> {this.id}{" "}
                        </div>
                    }
                />
            );
        }

        const
            {
                shortId,
                title,
                catToolProjectId,
                _id
            } = project;

        return (
            <>
                <Helmet>
                    <title>{shortId} - Mercury © RWS</title>
                </Helmet>
                <div className="project-details-content">
                    <DetailsPageHeader
                        title={title}
                        projectId={catToolProjectId}
                        shortId={shortId}
                    />
                    <div
                        style={{ padding: "5px 0 10px 0" }}
                        className="project-details-body"
                    >
                        {this.projectDetails()}
                        {this.tabs()}
                        <BackTopButton />
                    </div>
                    {   project && _id
                        && <Collapse
                            collapsible="header"
                            defaultActiveKey={['1']}
                            items={[
                                {
                                  key: "1",
                                  label: "Project Notes",
                                  children: <Commenter id={_id} placeholder={`Add notes to "${title}" project`} comments={notes}/>,
                                },
                              ]}
                            style={{ border: "1px solid var(--grey)" }}
                       />
                    }
                </div>
            </>
        )
    }
}

const ProjectDetailsComponent = function (props: any) {

    const
        context = useMercuryContext(),
        { shortId } = useParams();

    return <ProjectDetails context={context} location={props.location} projectId={shortId} />
}

export default ProjectDetailsComponent;
