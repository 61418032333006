import {
  TableAbstract,
  CustomTooltip,
  FiniteListFilter,
  TextFilter,
  ListFilter,
} from "../../Components";
import { useMercuryContext } from "../../user-context";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import TimezoneConverter from "../../timezone-converter";
import { Link } from "react-router-dom";
import { Space, Tooltip } from "antd";
import SesameDropdown from "../../stories/Sesame/Dropdown/SesameDropdown";
import SesameAccountDialog from "../../stories/SesameAccount/SesameAccountDialog";
import {
  failedNotification,
  successfulNotification,
} from "../../utils/notifications";
import ResetPassword from "./ResetPassword";
import {
  roles,
  statusOptions,
  tcOptions,
} from "../../stories/SesameAccount/Json";
import LanguageCodeFilter from "../../Search/LanguageCode.filter";
import { FiniteListProps } from "../../stories/FiniteListFilter/FiniteListFilter";
import ProductAreaFilter from "../../Search/ProductArea.filter";
import { TextFilterProps } from "../../stories/TextFilter/TextFilter";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import SuspensionRequest from "./SuspensionRequest";
import { seasameStatusColumn } from "../../Search/columnsConfig";
import SesameStatusFilter from "./SesameStatus.filter";

const Role = (props: FiniteListProps) => (
  <FiniteListFilter {...props} name="role" title="Program Role" data={roles} />
);

const Affiliation = (props: FiniteListProps) => (
  <FiniteListFilter
    {...props}
    name="affiliation"
    title="Affiliation"
    data={[
      { key: "External", value: "External", title: "External" },
      { key: "RWS_Internal", value: "RWS_Internal", title: "RWS Internal" },
    ]}
  />
);

const AccountSuspended = (props: TextFilterProps) => (
  <FiniteListFilter
    {...props}
    name="accountSuspended"
    title="Account Suspended"
    data={statusOptions}
  />
);

const SesameID = (props: TextFilterProps) => (
  <TextFilter {...props} name="sesameIds" title="Sesame ID" />
);

const AccountTCSuspended = (props: FiniteListProps) => {
  return (
    <FiniteListFilter
      {...props}
      name="accountTCSuspended"
      title="TC Suspended"
      data={tcOptions}
    />
  );
};

const getStatusIcon = (text: string) => {
  let status;

  if (text === "Yes") {
    status = <CheckOutlined style={{ color: "var(--tertiary-color)" }} />;
  } else if (text === "No") {
    status = <CloseOutlined style={{ color: "var(--red)" }} />;
  } else {
    status = null;
  }

  return status;
};

class SesameSearch extends TableAbstract<any> {
  defaultSortField = "requestedDate";
  FilterComponents = [
    {
      id: "role",
      name: "role",
      title: "Program Role",
      component: Role,
      active: true,
    },
    {
      id: "code",
      title: "Language Code",
      name: "languageCode",
      component: LanguageCodeFilter,
      active: false,
    },
    {
      id: "affiliation",
      title: "Affiliation",
      name: "affiliation",
      component: Affiliation,
      active: false,
    },
    {
      id: "status",
      name: "status",
      title: "Status",
      component: SesameStatusFilter,
      active: true
  },
    {
      id: "productLine",
      title: "Product Line",
      name: "productLine",
      component: ProductAreaFilter,
    },
    {
      id: "requesterEmail",
      title: "Requester Email",
      name: "requesterEmail",
      component: ListFilter,
    },
    {
      id: "suspended",
      title: "Account Suspended",
      name: "accountSuspended",
      component: AccountSuspended,
    },
    {
      id: "sesame",
      title: "Sesame ID",
      name: "sesameIds",
      component: SesameID,
    },
    {
      id: "tcsuspended",
      title: "TC Suspended",
      name: "accountTCSuspended",
      component: AccountTCSuspended,
    },
  ];

  commonFields = (id: string, title: string) => {
    return {
      id,
      dataIndex: id,
      title,
      sorter: true,
      fixed: undefined,
      width: 150,
      render: (text: string) => <CustomTooltip text={text} length={17} />,
    };
  };

  columns = [
    {
      id: "_id",
      title: "ID",
      dataIndex: "_id",
      width: 80,
      fixed: undefined,
      render: (id: string, record: any) => (
        <Link to={`/sesame/${id}`} id={id}>
          {id?.slice(-6)}
        </Link>
      ),
    },
    {
      id: "requestedDate",
      title: "Requested Date",
      dataIndex: "requestedDate",
      width: 180,
      fixed: undefined,
      render: (text: string) => <TimezoneConverter date={text} />,
    },
    {
      id: "requestType",
      title: "Request Type",
      dataIndex: "requestType",
      sorter: true,
      fixed: undefined,
      width: 150,
      render: (text: string) => <CustomTooltip text={text} length={17} />,
    },
    {
      id: "requesterEmail",
      title: "Requester Email",
      dataIndex: "requesterEmail",
      sorter: true,
      fixed: undefined,
      width: 200,
      render: (text: string) => <CustomTooltip text={text} length={25} />,
    },
    {
      ...this.commonFields("role", "Program/Project Role"),
    },
    {
      ...this.commonFields("languageCode", "Language Code"),
    },
    {
      ...this.commonFields("affiliation", "Affiliation"),
    },
    {
      ...this.commonFields("productLine", "Product Line"),
    },
    {
      ...this.commonFields("contentType", "Content Type"),
    },
    {
      ...this.commonFields("fileSharing", "Automated file sharing in Polyglot"),
    },
    {
      ...this.commonFields(
        "emailCommunications",
        "Include in HO emails and other email communications"
      ),
    },
    {
      ...this.commonFields("fullName", "Full Name"),
    },
    {
      ...this.commonFields("city", "City, Country"),
    },
    seasameStatusColumn,
    {
      id: "emailAddress",
      title: "Email ID of new resource",
      dataIndex: "emailAddress",
      sorter: true,
      fixed: undefined,
      width: 180,
      render: (text: string) => <CustomTooltip text={text} length={17} />,
    },
    {
      id: "notes",
      title: "Additional Instructions for Training team",
      dataIndex: "notes",
      sorter: true,
      fixed: undefined,
      width: 220,
      render: (text: string) => <CustomTooltip text={text} length={17} />,
    },
    {
      id: "suspended",
      title: (
        <Tooltip
          title="Account Suspended"
          placement="left"
          color={"var(--tertiary-color)"}
        >
          Account Suspended
        </Tooltip>
      ) as any,
      dataIndex: "accountSuspended",
      sorter: true,
      width: 60,
      render: (text: string) => getStatusIcon(text),
      fixed: undefined,
    },
  ];

  async handleSubmit(data: any, callback: any) {
    if (data?.requestType === "newSesame") {
      try {
        const res = await this.userContext.gpClient.post(
          `/google-gp-v1/sesameAccounts/newRequest`,
          data
        );
        callback();
        successfulNotification(
          "Success",
          `Sesame Id ${res?.data?.primaryEmail} was created`
        );
      } catch (error: any) {
        callback();
        failedNotification(
          "Error",
          `${
            error?.response?.data?.details?.split("\n")?.[0] ||
            error?.response?.data?.message
          }`
        );
      }
    }
  }

  async handleSelection(type: any) {
    //@ts-ignore
    this.setState({ currentSelection: type });
    if (type === "4-1") {
      console.log("Suspend", this.state.selectedRows);
    } else if (type === "4-2") {
      console.log("Activate", this.state.selectedRows);
    }
  }

  async resetSelection() {
    //@ts-ignore
    this.setState({ currentSelection: "" });
  }

  renderActions() {
    //@ts-ignore
    const { currentSelection } = this.state;
    const { userDetails } = this.props.context;

    return (
      <Space size={"large"}>
        <SesameAccountDialog
          onSubmit={(data, callback) => this.handleSubmit(data, callback)}
          requesterEmail={userDetails?.email}
          buttonLabel="Create"
        />
        {currentSelection === "3" ? (
          <ResetPassword
            userContext={this.userContext}
            reset={() => this.resetSelection()}
          />
        ) : null}
        {currentSelection === "4-1" ? (
          <SuspensionRequest
            userContext={this.userContext}
            selectedRows={this.state.selectedRows}
          />
        ) : null}
        <SesameDropdown
          buttonLabel="Alibaba 2.0"
          onSelect={(selection) => this.handleSelection(selection)}
          disabledOptions={this.state.selectedRows?.length > 0 ? [] : ["4"]}
        />
      </Space>
    );
  }

  async fetch() {
    const { sortOrder, sortField } = this.state;

    const res = await this.userContext.gpClient.post(
      `/google-gp-v1/sesameAccounts/search`,
      {
        filter: this.filtersToQuery(),
        sort: {
          [sortField || "lastUpdatedTimestamp"]:
            sortOrder === "ascend" ? 1 : -1,
        },
        pagination: {
          pageNumber: this.currentPage || 1,
          resultsPerPage: this.itemsPerPage,
        },
      }
    );
    return { data: res.data } as any;
  }

  pageTitle = "Sesame Tracker";
  title = "Sesame Tracker";
  pageClass = "projectTable";
}

const SesameTable = () => {
  const context = useMercuryContext(),
    breaks = useBreakpoint();

  return (
    <SesameSearch
      id="Sesame Tracker"
      context={context}
      useLegacyEndpoints={false}
      breaks={breaks as any}
    />
  );
};

export default SesameTable;
